import React from 'react';
import { graphql, Link } from 'gatsby';
import { Helmet } from 'react-helmet';
import { format } from 'date-fns';
import frLocale from 'date-fns/locale/fr';

import PageLayout from '../../layouts/Page';

export default function Ressources({ data }) {
  return (
    <PageLayout>
      <Helmet>
        <title>
          Retrouvez les derniers articles rédigés par l'équipe Thank-You
          Analytics
        </title>
        <meta
          name="description"
          content="Contenus à destination des équipes marketing permettant de monter en compétence sur des sujets autour de la mesure de la performance publicitaire."
        />
      </Helmet>
      <div className="bg-white">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:pt-24 sm:pb-16 sm:px-6 lg:px-8 divide-y-2 divide-gray-200">
          <div>
            <h2 className="text-3xl tracking-tight font-extrabold text-gray-900 sm:text-4xl">
              Ressources
            </h2>
            <div className="mt-3 sm:mt-4 lg:grid lg:grid-cols-2 lg:gap-5 lg:items-center">
              <p className="text-xl text-gray-500">
                Retrouvez les derniers articles écrits par l'équipe Thank-You
                Analytics
              </p>
            </div>
          </div>
          <div className="mt-6 pt-10 grid gap-16 lg:grid-cols-2 lg:gap-x-5 lg:gap-y-12">
            {data.allContentfulBlogPost.nodes.map((post) => (
              <div key={post.contentful_id}>
                <p className="text-sm text-gray-500">
                  <time>
                    {format(new Date(post.publishDate), 'dd MMMM yyyy', {
                      locale: frLocale
                    })}
                  </time>
                </p>
                <Link to={post.slug} className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900">
                    {post.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">{post.intro}</p>
                </Link>
                <div className="mt-3">
                  <Link
                    to={post.slug}
                    className="text-base font-semibold text-green-brand hover:text-green-500"
                  >
                    Continuer à lire
                  </Link>
                </div>
              </div>
            ))}
            {data.allMarkdownRemark.edges.map((post) => (
              <div key={post.node.id}>
                <p className="text-sm text-gray-500">
                  <time>{post.node.frontmatter.date}</time>
                </p>
                <Link to={post.node.frontmatter.slug} className="mt-2 block">
                  <p className="text-xl font-semibold text-gray-900">
                    {post.node.frontmatter.title}
                  </p>
                  <p className="mt-3 text-base text-gray-500">
                    {post.node.frontmatter.intro}
                  </p>
                </Link>
                <div className="mt-3">
                  <Link
                    to={post.node.frontmatter.slug}
                    className="text-base font-semibold text-green-brand hover:text-green-500"
                  >
                    Continuer à lire
                  </Link>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </PageLayout>
  );
}

export const pageQuery = graphql`
  query {
    allContentfulBlogPost {
      nodes {
        contentful_id
        title
        slug
        intro
        publishDate
      }
    }
    allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            intro
            slug
            date(formatString: "DD MMMM yyyy", locale: "FR-fr")
          }
          id
        }
      }
    }
  }
`;
